#Filters{
    padding: 16px 0;
    position: relative;
    z-index: 2;
    /* background: linear-gradient(to right, rgba(130, 140, 236, 100), rgba(75, 89, 224, 100)); */
    background: #2C3948;
}

#Filters .form-control {
    padding: 0.9rem 4rem 0.9rem 0.8rem;
    font-size: 14px;
    line-height: 1.5;
    border: 0px;
    border-radius: .20rem;
    font-weight: 300;
    height: 100%;
}

#Filters .form-group{
    position: relative;
}

#Filters .form-control::placeholder {
    color: #565f65;
}

ul.category-filter {
    background: #ffffff;
    border-radius: .20rem;
    padding: 7px;
    margin: 0;
    font-size: 14px;
}

ul.category-filter li:first-child{
    border-right: 1px solid #EFEFEF;
}

ul.category-filter li{
    display: inline-block;
    width: 50%;
    text-align: center;
}

ul.category-filter li a {
    background: #ffffff;
    cursor: pointer;
    display: block;
    transition-duration: 0.4s;
    border-radius: 4px;
    padding: 8px 9px;
}

ul.category-filter li a:hover{
    background: #EBEEFD;
}

.blocked-filter {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    cursor: pointer;
    left: 0;
    z-index: 2;
    top: 0;
}

.icon-search img {
    width: 16px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.icon-search {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #DB7124;
    border-radius: 0px 4px 4px 0px;
    height: 100%;
    cursor: pointer;
    width: 50px;
    border: 0;
    transition-duration: 0.4s;
}

.icon-search:hover{
    background-color: #2C3948;
    transition-duration: 0.2s;
}

.nav-lots-filter {
    background-color: #ffffff;
    padding: 26px 26px 13px 26px;
    border-radius: 2px;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
}

.nav-lots-filter .title-nav-lots-filter {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #2C3948;
    cursor: inherit;
}

.nav-lots-filter p {
    color: #707070;
    font-weight: 400;
    padding: 0 15px 0 0;
    font-size: 14px;
    width: 25%;
    line-height: 16px;
    cursor: pointer;
}

.nav-lots-filter p > a:hover{
    color: #6672E5 !important;
}

.nav-sticky {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.01);
}

.nav-sticky + .p-section{
    padding-top: 113px;
}

/* responsive */

@media screen and (max-width: 767px){
    ul.category-filter{
        margin: 0 0 10px 0;
    }

    #Filters .form-control{
        margin-bottom: 10px;
    }
}
