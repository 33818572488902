.senovea-global-alert-wrap{
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    z-index: 2000;
}

.senovea-global-alert-wrap .alert{
    margin-bottom: 0px;
    position: fixed;
    background-color: #DB7124;
    align-items: center;
    bottom: 15px;
    color: #ffffff;
    left: 15px;
    text-align: center;
    border-radius: 4px;
    border: 0px;
    width: 360px;
    padding: 20px;
    line-height: 22px;
}

.senovea-global-alert-wrap .alert .senovea-global-alert-close{
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
}
.senovea-global-alert-wrap .alert .senovea-global-alert-close > div{
    line-height: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
}
.senovea-global-alert-wrap .alert .senovea-global-alert-close > div:hover{
    cursor:pointer;
}