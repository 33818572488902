.preloader-global{
    height: 100vh;
    width: 100%;
    color: #ffffff;
    background: rgba(255,255,255,0.85);
    color: #2C3948;
    font-size: 22px;
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 1000;
}

.preloader-global .content-preloader-global{
    height: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    display: inline-block;
}
