#nav-create-panier {
    padding: 10px 0;
    background: #ffffff;
}

#nav-create-panier .row{
    align-items: center;
}

#nav-create-panier p {
    margin: 0;
}
