.preloader{
    background-color: #ffffff;
    height: 44px;
    width: 44px;
    display: inline-block;
    border-radius: 60px;
    border: 1px solid #D9E1E8;
    padding: 8px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.1);
}

.preloader img{
    fill: #ffffff;
    animation: preloaderAnimate 1s linear infinite;
    height: auto;
    width: 26px;
}