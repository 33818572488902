#footer-app {
    width: 100%;
    font-size: 14px;
    font-weight: lighter;
    color: #DDDDDD;
    background-color: #2C3948;
    padding: 30px 0;
}

#logo-footer {
    width: 120px;
    height: auto;
}

#btn-call{
    background-color: #DB7124;
    border-radius: 6px 0 0 0;
    color: #ffffff;
    z-index: 2;
    padding: 18px 22px;
    position: absolute;
    transition-duration: 0.4s;
    cursor: pointer;
    font-size: 16px;
    bottom: 81px;
    right: 0;
    align-content: center;
    justify-content: center;
    display: flex;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}

#btn-call:hover{
    background-color: #6B77E7 !important;
}

#btn-call img {
    width: 25px;
    padding-top: 6px;
}

/* responsive */

@media screen and (max-width: 767px){
    #footer-app{
        padding: 0 0 30px 0;
    }

    #btn-call {
        border-radius: 0;
        display: block;
        text-align: center;
        position: relative;
        font-size: 14px;
        margin-bottom: 30px
    }
}
