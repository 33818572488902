
.cartBanner {
    background-color:#DB7124;
    color:rgb(0, 0, 0);
    text-align: center;
    margin: 0 auto;
}

.cartBanner b {
    color:unset;
}

.custom-select {
    position: relative;
    width: 100%;
    font-size: 14px;
  }
  
  .selected-option {
    text-align: left;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .option {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f5f5f5;
  }
  