.modal-header{
    text-align: center;
}

.modal-header h5{
    width: 100%;
}

.modal-body button{
    text-align: center;
}