/* Style global */

html, body, #root, .root-inside {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: rgba(234, 239, 245, 100);
    font-family: 'Work Sans', sans-serif;
    color: #2C3948;
    font-size: 16px;
}

@font-face {
  font-family: 'CocoGoo';
  src: url('../assets/fonts/COCOGOOSELETTERPRESS.ttf') format('truetype');
}

#main-app {
    min-height: 100%;
}

.input-error{
  color: red;
}

.main {
    height: auto;
    min-height: 100%;
    padding-bottom: 70px;
}

.main-home {
    height: auto;
    min-height: 100%;
    padding-bottom: 70px;
}

.p-section-bloc{
    padding: 100px 0;
}

.p-section{
    padding: 30px 0 30px 0;
    min-height:100%;
}

a {
    color: inherit;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
}

a:hover{
    color: #DB7124;
    text-decoration: inherit;
    transition-duration: 0.4s;
}

b {
    font-weight: 500;
    color: #2C3948;
}

button:focus {
    outline: none;
}

.container{
    max-width: 1245px;
    min-height: 100%;
}

#SupplierListContainer{
    max-width: 85%;
    min-height: 100%;
}

.document-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preloader{
    background-color: #ffffff;
    height: 44px;
    width: 44px;
    display: inline-block;
    border-radius: 60px;
    border: 1px solid #D9E1E8;
    padding: 8px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.1);
}

.preloader img{
    fill: #ffffff;
    animation: preloaderAnimate 1s linear infinite;
    height: auto;
    width: 26px;
}

.preloader-connect-user {
    display: inline-block;
    margin-left: 10px;
}

.preloader-connect-user img{
    fill: #ffffff;
    animation: preloaderAnimate 1s linear infinite;
    height: auto;
    width: 16px;
}

@keyframes preloaderAnimate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.arrow {
    color: white;
    border-radius: 50%;
    border: none;
    width: 40px;
    cursor: pointer;
    height: 40px;
    background: rgb(157, 169, 232);
    transition-duration: 0.6s;
}

.preloader-product img {
    width: 30px;
}

.arrow:hover{
    background: rgba(75, 89, 224, 100);
    transition-duration: 0.6s;
}

.arrow img {
    width: 11px;
}

.article-bloc {
    padding: 10px 10px 10px 20px;
    display: block;
    background: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    -webkit-border-radius: 4px;
    line-height: 18px;
    color: #565F65;
    font-weight: lighter;
    -moz-border-radius: 4px;
    border: 1px solid #D9E1E8;
    margin-bottom: 4px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}

.customers-block {
    padding: 10px 10px 10px 20px;
    display: flex;
    background: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    -webkit-border-radius: 4px;
    line-height: 18px;
    color: #565F65;
    font-weight: lighter;
    -moz-border-radius: 4px;
    border: 1px solid #D9E1E8;
    margin-bottom: 4px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}

.customer-name {
    display: block; /* Assurez-vous que cela est traité comme un bloc pour appliquer la largeur */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px; /* Ceci devrait fonctionner si le parent a une largeur contrainte */
}



.bloc-download {
    padding: 10px 20px;
    display: block;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    line-height: 18px;
    color: #565F65;
    font-weight: lighter;
    -moz-border-radius: 4px;
    border: 1px solid #D9E1E8;
    margin-bottom: 4px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}

.bloc-download img{
    margin-bottom: 0;
}

.select-product{
    display: inline-block;
    width: auto;
    font-size: 0.7rem;
}

.article-bloc .row,
.bloc-download .row {
    justify-content: space-between;
    align-items: center;
}

.bloc-download ul{
    padding: 0;
    margin: 0;
    text-align: right;
}

.bloc-download li{
    list-style-type: none;
    margin-bottom: 10px;
}

.bloc-download li a{
    text-decoration: underline;
}

.bloc-download li:last-child{
    margin-bottom: 0;
}

.article-bloc p, .bloc-download p{
    margin: 0;
}

p.title-bloc-download {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 6px;
    color: #2C3948;
}

/* user page "pagination" */

.pagination {
    padding-top: 30px;
    list-style: none;
    border-radius: .25rem;
    text-align: center;
    display: block;
    width: 100%;
}

.pagination li {
    display: inline-block;
}

.pagination li a {
    height: 40px;
    width: 40px;
    display: block;
    vertical-align: middle;
    cursor: pointer;
    line-height: 42px;
}

.pagination .active a{
    color: #ffffff;
    background: #2C3948;
    border-radius: 70px;
}

.card{
    border: 0px;
}

.card-body {
    padding: 0;
    border-top: 1px solid #D9E1E8;
    padding-top: 17px;
    padding-bottom: 10px;
    margin-top: 10px;
    line-height: 18px;
}

.card-details-button {
    display: flex;
}
.fileLinkDocument:hover svg {
    fill: #DB7124;
}

.btn-green{
    background-color: #DB7124;
    padding: 14px 20px;
    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;
    border: 0;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}
.btn-dynamic-width {
    display: inline-block;
    width: auto;
}

.btn-green.min, .btn-white.min{
  display: inline-block;
  width: auto !important;
}

.btn-red{
    background-color: #df214d;
    padding: 10px 16px;
    display: inline-block;
    text-align: center;
    border: 0;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
}

.btn-white {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 10px 16px;
    display: inline-block;
    font-weight: 500;
    border-radius: 4px;
    color: #000000;
    font-size: 12px;
    cursor: pointer;
}

.btn-white:disabled {
    background-color: #ffffff;
    color: #000000;
    cursor: default;
}

.btn-white.full {
    width: 100%;
    padding: 1.0rem 0.5rem;
    font-size: 14px;
    text-transform: uppercase;
    transition: 300ms
}

.btn-white.full:hover {
  background-color: #5a6268;
  color: white;
}

.btn-grey{
    background-color: #5a6268;
    border: 1px solid #545b62;
    padding: 10px 16px;
    display: inline-block;
    font-weight: 500;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}

.btn-grey:hover {
    background-color: #ffffff;
    color: #000000;
}

.btn-grey.full {
    width: 100%;
    padding: 1.0rem 0.5rem;
    font-size: 14px;
    text-transform: uppercase;
}

.btn-green:hover{
    background-color: #565f65;
}

#btn-call:hover{
    background-color: #15c3b7;
}

.price {
    color: #DB7124;
}

/* CENTRALIS PAGE */
.centralis-offer-wrap{
    position:relative;
    display:flex;
    max-width:1250px;
    justify-content:center;
    align-items:flex-start;
    padding:25px;
    margin: 0 auto;
}
.centralis-offer-wrap .centralis-offer-left{

    margin:0 15px 0 0;
    flex-grow:1;

}
.centralis-offer-wrap .centralis-offer-right{
    width:350px;
    border-radius: 4px;
}

/* LOGO */
.centralis-offer-logo{
    padding:15px;
    margin-bottom:15px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 225, 232);
    border-radius: 4px;
    box-shadow: rgba(61, 68, 139, 0.05) 0px 2px 16px;
}

/* INFORMATIONS */
.centralis-offer-informations{
    padding:15px;
    margin-bottom:15px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 225, 232);
    border-radius: 4px;
    box-shadow: rgba(61, 68, 139, 0.05) 0px 2px 16px;
}
.centralis-offer-informations.centralis-offer-informations-waiting{
    background:#FAFAFA;
}

.centralis-offer-dl{
    padding:15px;
    margin-bottom:15px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 225, 232);
    border-radius: 4px;
    box-shadow: rgba(61, 68, 139, 0.05) 0px 2px 16px;
}
.centralis-offer-dl form{
    margin:0;
}
.centralis-offer-dl button{
    width:100%;
    border-radius:1px;
    border:1px #E3E3E3 solid;
    background:#FAFAFA;
    padding: 15px 0;
    font-size:12px;
    font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    max-width:350px;
    font-weight:600;
}
.centralis-offer-dl button:hover{
    cursor:pointer;
}

/* ACTIONS */

.centralis-offer-actions{
    margin-bottom:15px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 225, 232);
    border-radius: 4px;
    box-shadow: rgba(61, 68, 139, 0.05) 0px 2px 16px;
    /*position:absolute;*/
    /*bottom:0;*/
    padding:15px;
    width:100%;
    /*border-top:1px #E3E3E3 solid;*/
}

.centralis-offer-actions form{
    margin:0;
}

.centralis-offer-actions input, .centralis-offer-actions button{
    margin-bottom:15px;

}

.centralis-offer-actions input[type="text"]{
    display:block;
    width:100%;
    border-radius:1px;
    border:1px #E3E3E3 solid;
    background:#FFF;
    padding: 15px;
    font-size:12px;
    font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    max-width:350px;
}
.centralis-offer-actions input[type="password"]{
    display:block;
    width:100%;
    border-radius:1px;
    border:1px #E3E3E3 solid;
    background:#FFF;
    padding: 15px;
    font-size:12px;
    font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    max-width:350px;
}

.centralis-offer-actions button{
    display:block;
    width:100%;
    border-radius:1px;
    border:1px #E3E3E3 solid;
    background:#FAFAFA;
    padding: 15px 0;
    font-size:12px;
    font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    max-width:350px;
}
.centralis-offer-actions button:hover{
    cursor:pointer;
}
.centralis-offer-actions button.centralis-offer-action-accept{
    background:#4b59e0;
    border-color:#4b59e0;
    color:#FFF;
    font-weight:600;
}
.centralis-offer-actions button.centralis-offer-action-reject{
    font-weight:600;
    margin-bottom:0px;

}

/* CENTRALIS STYLES */

.centralis-block{
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 225, 232);
    border-radius: 4px;
    box-shadow: rgba(61, 68, 139, 0.05) 0px 2px 16px;
    padding:15px;
    position: relative;
}

/* bloc lot */
.title-bloc-lot{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    font-size: 22px;
    color: #DB7124;
    padding: 20px 35px;
    border: 1px solid #D9E1E8;
    font-weight: 500;
    margin-bottom: 11px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}

.title-bloc-lot > p{
    margin-bottom: 0;
}

.bloc-lot {
    margin-bottom: 5px;
}

/* cookies notice */
#cookie-notice{
    position: fixed;
    left: 20px;
    font-size: 14px;
    color: #ffffff;
    bottom: 20px;
    /* background: linear-gradient(to right, rgba(130, 140, 236, 100), rgba(75, 89, 224, 100)); */
    background: #2C3948;
    padding: 26px;
    border-radius: 4px;
    box-shadow: 0 0px 20px rgba(61, 68, 139, 0.2);
    width: 470px;
    z-index: 2;
}

#cookie-notice .btn-green {
    text-transform: inherit;
    display: inline-block;
    width: auto;
    font-size: 12px;
    padding: 10px 15px;
}

.displayBlocOpacity{
    opacity: 0;
    transition-duration: 1s;
    transition-delay: 0.2s;
    margin-left: -580px;
}


/* senovea page supplier */

.senovea-page-supplier{
    padding: 100px;
}
.senovea-page-supplier-wrap{
    background: #FFF;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #D9E1E8;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
}
.senovea-page-supplier-title{
    font-size: 22px;
    color: #DB7124;
    font-weight: 500;
}

/* page détail panier */

#section-infos-detail-panier{
    padding: 16px 0;
    /* background: linear-gradient(to right, rgba(130, 140, 236, 100), rgba(75, 89, 224, 100)); */
    background: #8E8E8E;
    color: #ffffff;
    font-weight: lighter;
    z-index: 2;
}

#section-infos-detail-panier b{
    color: #ffffff;
}

#section-infos-detail-panier .row{
    align-items: center;
}

.date-detail-panier{
    font-size: 21px;
}

.title-infos-detail-panier {
    text-align: center;
    margin-bottom: 0;
    font-size: 22px;
 }
/* panier */

.bloc-panier{
    border-radius: 4px;
    font-size: 18px;
    padding: 25px;
    border: 1px solid #D9E1E8;
    font-weight: 500;
    margin-bottom: 20px;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
    background:#FFF;
}

.bloc-panier-sent{
    /* background:linear-gradient(to right, rgba(130, 140, 236, 100), rgba(75, 89, 224, 100)); */
    background: #2C3948;
    /* background: #2C3948; */
    color:#FFF;
}

.title-panier{
    font-size: 22px;
    margin: 0;
    width: 100%;
    text-align: right;
}

.bloc-panier-sent .statut-panier{
    background-color: #ffffff;
    color: #6B77E7;
}

.list-infos-panier{
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 300;
}

.list-infos-panier b, .date-panier b{
    font-weight: 500;
    color: #ffffff;
}

.date-panier {
    font-size: 20px;
    font-weight: 300;
}

.list-infos-panier li {
    display: inline-block;
    padding-right: 20px;
    padding-left: 20px;
    border-right: 1px solid #DB7124;
}

.list-infos-panier li:last-child{
    padding-right: 0;
    border: 0;
}

.list-infos-panier li:first-child{
    padding-left: 0;
}

.bloc-panier .arrow {
    float: right;
}

.bloc-panier-table{
    width: 100%;
    box-shadow: 20px 20px 16px rgba(61, 68, 139, 0.05);
    border-radius: 4px;
    background:#FFF;
    display:none;
    margin-top: -13px;
    margin-bottom: 20px;
}
.bloc-panier-table p{
    margin:0;
}
.bloc-panier-table > .bloc-panier-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:1px;
    border-bottom:1px rgba(234, 239, 245, 100) solid;
}

.bloc-panier-table > .bloc-panier-header > div{
    margin: 0;
    font-size:12px;
    font-weight: 600;
    color: #000;
    padding: 15px;
    width:14% !important;
}
.bloc-panier-table > .bloc-panier-header > div.bloc-panier-header-lot{
    width: 16% !important;
}

.bloc-panier-content-lot{
    margin-bottom:1px;

}


.bloc-panier-content-lot .bloc-panier-content-lot-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px rgba(234, 239, 245, 100) solid;
}
.bloc-panier-content div:last-child .bloc-panier-content-lot:last-child  .bloc-panier-content-lot-header{
    border-bottom:none;
}
.bloc-panier-content-lot .bloc-panier-content-lot-header > div{
    margin: 0;
    font-size:12px;
    font-weight: 400;
    color: #000;
    padding: 5px 15px;
    width:14% !important;
}
.bloc-panier-content-lot .bloc-panier-content-lot-header > div.bloc-panier-content-lot-header-lot{
    width: 16% !important;
}

.bloc-panier-content-lot .bloc-panier-content-lot-content{
    display: none;
}

#navigation-detail-panier {
    margin-bottom: 30px;
    background: #ffffff;
    padding: 15px 0;
}

#navigation-detail-panier button {
    width: 100%;
    padding: 12px 0px;
    border: 0px;
    transition-duration: 0.4s;
}

#navigation-detail-panier button:hover,
#navigation-detail-panier button:visited,
#navigation-detail-panier button:link,
#navigation-detail-panier button:active{
    background: rgb(235, 238, 253);
    font-weight: 500;
    transition-duration: 0.4s;
    cursor: pointer;
}

#navigation-detail-panier .col-md-4 {
    border-right: 1px solid #dddddd;
}

#navigation-detail-panier .col-md-4:last-child{
    border-right: 0px;
}

/**
    CARD DROPDOWN
**/
.dropdown-menu{
    padding: 0;
}
#header-app .dropdown-item{
    font-size: 1em;
    padding: 12px;
}

#header-app .dropdown-item h6{
    font-size: 1em;
}

#header-app .dropdown-item p {
    font-size: 0.75em;
}
#header-app .dropdown-item button {
    margin-top: 1.875em;
}
.articleLot{
    margin-bottom: 0.8em;
}


.header-bloc-panier {
    padding: 30px 15px;
}

.statut-free {
    color: #ffffff;
    background: #6B77E7;
    /*margin-right: 16px;*/
    font-size: 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    padding: 6px 10px;
    text-transform: uppercase;
}
.statut-formalized {
    color: #ffffff;
    background: #DB7124;
    margin-right: 16px;
    font-size: 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    padding: 6px 10px;
    text-transform: uppercase;
}

.statut-cart {
    color: #0e0e0e;
    background: #ffffff;
    margin-right: 16px;
    border: 1px solid rgb(128, 128, 128);
    font-size: 10px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    padding: 6px 10px;
    text-transform: uppercase;
}

.count-lots-panier{
    font-size: 20px;
}

.bloc-panier button.btn-green {
    width: 160px;
    padding: 10px 10px;
    font-size: 12px;
}

.bloc-suppliers{
    border: 1px solid #d9e1e8;
    box-shadow: 0px 2px 16px rgba(61, 68, 139, 0.05);
    border-radius: 4px;
    overflow: hidden;
}

.bloc-suppliers li{
    list-style-type: none;
    line-height: 30px;
}

.bloc-about {
    background: #ffff;
    margin: 0;
}

.content-bloc {
    padding: 42px 60px 32px 60px;
}

.content-bloc h3{
    font-size: 24px;
    margin-bottom: 37px;
}

.content-bloc-img {
    position: relative;
    overflow: hidden;
}

.content-bloc-img img {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
}

.section-bloc-about{
    margin-bottom: 20px;
}

.section-bloc-about:last-child{
    margin-bottom: 60px;
}

.list-section-about {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.list-section-about li {
    margin-bottom: 20px;
    padding-left: 70px;
    position: relative;
}
select.form-control:not([size]):not([multiple]) {
    height : calc(3rem + 2px);
}
.icon-list-about {
    background: #2C3948;
    height: 33px;
    display: flex;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    width: 33px;
    color: #ffffff;
    position: absolute;
    left: 0;
    border-radius: 44px;
    top: 0;
}

.section-bloc-about .list-section-about .link-bloc-about{
    color: #237CE2;
    cursor: pointer;
}

.section-bloc-about .list-section-about .link-bloc-about:hover{
    color: #DB7124;
}

.list-section-about li:last-child{
    margin: 0;
}

.table-about td{
    width: 25%;
}

.table thead th, .table td, .table th{
    border: 0;
    vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd){
    background-color: #EBEEFD;
}

.table-striped tbody tr:nth-of-type(odd) td {
    font-weight: bold;
}

.nav-account {
    padding: 15px 36px;
    margin: 0;
    border: 1px solid #D9E1E8;
    background-color: #ffffff;
}

.nav-account li {
    list-style-type: none;
    padding: 10px 0px;
}

.nav-account li .active{
    font-weight: 600;
}

.btn-quantity-count {
    background: none;
    border: 1px solid #dddddd;
    padding: 4px 6px;
    cursor: pointer;
}

.count-quantity {
    width: 25px;
    text-align: center;
}

/* media queries */

@media screen and (max-width: 600px){

    .main{
        padding-top: 4em;
    }
}

#formSupplier h1, #formSupplier h2, #formSupplier h3, #formSupplier h4 {
  color: #db7124 !important;
}

#formSupplier h4 {
  color: #db7124 !important;
  margin-top: 2rem;
}

.MuiStepIcon-root {
  color: red;
}

#formSupplier label{
  margin-top: 0.5rem;
  font-weight: bold;
}

#formSupplier .error-red{
  color: red;
  margin-top: 1.0rem;
}

#formSupplier .card{
  padding: 0.5rem;
  background-color: #eee;
}

#formSupplier .card.min{
  padding: 0rem;
}

#formSupplier .card h5{
  text-align: center;
}

#formSupplier .checkbox-label{
  font-weight: normal;
  margin-top: 0rem;
}

#formSupplier .col-sm-2{
  padding-right: 5px;
  padding-left: 5px;
}

#formSupplier .form-control{
  padding: .11rem .75rem;
  height: auto;
}

.fileLink{
  text-decoration: underline;
  color: #db7124;
  cursor: pointer;
}

.sticky{
  background-color: #db7124;
  color: white;
  font-size: 11px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 1.0rem;
  position: sticky;
  top: 0;
  z-index: 500;
}

#step10 .article p{
  font-size: 11px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0rem;
}

#step10 hr{
  margin-top: 0rem;
  margin-bottom: 0rem;
}

#step10 .form-control{
  height: auto;
  padding: 0 0.5rem;
}

#step4 hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#step4 .btn-white{
  padding: 5px 16px;
  margin-top: 5px;
}

#formSupplier label {
    margin-bottom: 0;
}

#formSupplier textarea{
  height: 250px !important;
}
.svg-size{
  width: 50px;
  max-width: 100%;
  margin-bottom: 20px;
}

.login{
  margin: auto;
  text-align: center;
}

.login .block{
  max-width: 420px;
  background-color: white;
  padding: 20px;
  margin: auto;
}

.fix-form-dev{
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: white;
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);
}

/* Grossit le caret des dropdown dans les menus */
.dropdown-toggle::after {
    border-top: .5em solid !important;
    border-right: .5em solid transparent !important;
    border-bottom: 0 !important;
    border-left: .5em solid transparent !important;
    vertical-align: 0.1em !important;
}

.hover-grey:hover {
    color: #2C3948;
}